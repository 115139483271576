<template>
    <div class="vue-vergleichsangebot-formular-wrapper mt-5 mb-5" v-if="data">
        <div class="row container-fluid-max">

            <div class="col-12 col-sm-12 col-lg-4 offset-lg-1">
                <form v-if="data.form" method="post" action=""
                      accept-charset="UTF-8" enctype="multipart/form-data">

                    <input type="hidden" name="toEmail" v-bind:value="toEmail">

                    <div v-html="csrfInput"></div>
                    <input type="hidden" name="action" value="contact-form/send">
                    <div v-html="redirectInput"></div>

                    <!-- <div class="row mt-3" v-if="data.form.upload">
                        <div class="w-100 label">
                            <h4>{{data.form.upload.label}}</h4>
                        </div>
                        <div class="form-group files w-100">
                            <input type="file" id="file" class="form-control" multiple name="attachment[]"
                                   v-on:change="onUploadChange($event)">
                            <span>{{data.form.upload.mainInputTitle}}</span>
                        </div>
                        <div>
                            <small>{{data.form.upload.helpLabel}}</small>
                        </div>
                      <div class="vue-recall-arrange-input-error mt-2" v-if="data.form.upload.isError">
                        {{data.form.upload.errorMsg}}
                      </div>
                    </div> -->

                    <div class="row mt-3" v-if="data.form.firma">
                        <h3 class="w-100">
                            {{data.form.firma.title}}
                        </h3>

                        <label for="antrag-company-name">
                            {{data.form.firma.label}}
                        </label>

                        <input id="antrag-company-name" type="text" name="message[CompanyName]"
                               class="form-control" v-model="data.form.firma.value"
                               @input="onCompanyNameChange()">

                        <div class="vue-recall-arrange-input-error mt-2 text-left"

                             v-if="data.form.firma.isError">
                            {{data.form.firma.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-3">
                        <h3 class="w-100">
                            {{data.form.personalInfo.mainTitle}}
                        </h3>

                        <label for="antrag-personal-info-title">
                            {{data.form.personalInfo.title.label}}
                        </label>
                        <div class="dropdown w-100">
                            <div class="arrow"></div>
                            <select class="form-control" id="antrag-personal-info-title" name="message[Title]"
                                    v-model="data.form.personalInfo.title.value"
                                    @change="onPersonalInfoTitleChange()">
                                <option disabled selected value>
                                    {{data.form.personalInfo.title.helpLabel}}
                                </option>
                                <option :value="tit" v-for="tit in data.form.personalInfo.title.titles" :key="tit">
                                    {{tit}}
                                </option>
                            </select>
                        </div>

                        <div class="text-left"

                             v-if="this.data.form.personalInfo.title.isError">
                            {{this.data.form.personalInfo.title.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label class="text-left" for="antrag-personal-info-name">
                            {{data.form.personalInfo.name.label}}
                        </label>
                        <input id="antrag-personal-info-name" type="text" name="message[Name]"
                               class="form-control"
                               @input="onPersonalInfoNameChange()"
                               v-model="data.form.personalInfo.name.value">
                        <div class="vue-recall-arrange-input-error mt-2"
                             v-if="data.form.personalInfo.name.isError">
                            {{data.form.personalInfo.name.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label class="text-left" for="antrag-personal-info-surname">
                            {{data.form.personalInfo.surname.label}}
                        </label>
                        <input id="antrag-personal-info-surname" type="text" name="message[Surname]"
                               class="form-control"
                               @input="onPersonalInfoSurnameChange()"
                               v-model="data.form.personalInfo.surname.value">
                        <div class="vue-recall-arrange-input-error mt-2"
                             v-if="data.form.personalInfo.surname.isError">
                            {{data.form.personalInfo.surname.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label class="text-left" for="antrag-pesonal-info-phone">
                            {{data.form.personalInfo.phone.label}}
                        </label>
                        <input id="antrag-pesonal-info-phone" type="text" name="message[Phone]"
                               class="form-control"
                               @input="onPersonalInfoPhoneChange()"
                               v-model="data.form.personalInfo.phone.value">
                        <div class="vue-recall-arrange-input-error mt-2"
                             v-if="data.form.personalInfo.phone.isError">
                            {{data.form.personalInfo.phone.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label class="subText" for="vue-recall-arrange-email">
                            {{data.form.personalInfo.email.label}}
                        </label>
                        <input id="vue-recall-arrange-email" type="email" name="fromEmail"
                               class="form-control"
                               @input="onEmailChange()"
                               v-model="data.form.personalInfo.email.value">
                        <div class="vue-recall-arrange-input-error mt-2"
                             v-if="data.form.personalInfo.email.isError">
                            {{data.form.personalInfo.email.errorMsg}}
                        </div>
                    </div>

                    <div class="row align-items-center mt-5 datenschutz">
                        <label class="check" for="vue-recall-arrange-privacy"></label>
                        <input id="vue-recall-arrange-privacy" type="checkbox"
                               name="vue-recall-arrange-privacy"
                               class="form-control checkbox"
                               @input="onPrivacyChange()" v-model="data.form.privacy.value">
                        <div class="subText text-left mt-3 checkbox-text" v-html="data.form.privacy.label">
                        </div>
                        <div class="vue-recall-arrange-input-error mt-2"
                             v-if="data.form.privacy.isError">
                            {{data.form.privacy.errorMsg}}
                        </div>
                    </div>

                    <div class="row mt-5">
                        <input class="submitButtonWrapper" type="submit" :value="data.form.btnSubmit.label"
                               @click="checkForm">
                    </div>

                    <div class="row mt-5" v-if="(!hasFormSomeError) && hasFormError">
                        <div class="col-1 p-0">
                                <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 21.8 29" style="enable-background:new 0 0 21.8 29;" xml:space="preserve">
                                <style type="text/css">
                                    .st0{fill:#EA0000;}
                                    .st1{enable-background:new    ;}
                                    .st2{fill:#FFFFFF;}
                                </style>
                                <g id="Gruppe_3395" transform="translate(-1111.405 -583)">
                                    <g id="Gruppe_1754" transform="translate(266.775 -2443.232)">
                                        <g id="Pfad_3416">
                                            <path class="st0" d="M855.6,3051.1c-6,0-10.9-4.9-10.9-10.9c0-6,4.9-10.9,10.9-10.9s10.9,4.9,10.9,10.9c0,4.7-3,8.9-7.5,10.4
                                                C857.9,3050.9,856.7,3051.1,855.6,3051.1z"/>
                                        </g>
                                    </g>
                                    <g class="st1">
                                        <path class="st2" d="M1123.9,602.8c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                                            C1123.3,601.5,1123.9,602.1,1123.9,602.8z M1123.5,600.6h-2l-0.4-9.5h2.7L1123.5,600.6z"/>
                                    </g>
                                </g>
                                </svg>
                        </div>
                        <div class="col-11 vue-recall-arrange-input-error">
                            {{data.form.formErrorMsg.label}}
                        </div>
                    </div>
                </form>
            </div>

            <div></div>

            <div class="col-12 col-sm-12 col-lg-5 offset-lg-1">
                <div class="row mt-3 list">
                    <div class="w-100 mb-4">
                        <img :src="data.textBlock.image" class="w-25">
                    </div>
                    <div class="text-left forder-ul-li" v-html="data.textBlock.text">
                    </div>
                    <div class="subText text-left mt-5 pt-5" v-html="data.textBlock.infoText">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "VergleichsangebotFormularComponent",
        props: ['data', 'csrfInput', 'redirectInput', 'baseUrl', 'toEmail'],
        data: function () {
            return {
                hasFormError: false
            }
        },
        computed: {
            hasFormSomeError: function () {
                return this.isError()
            }
        },
        methods: {
            isError: function () {
                if (this.data.form.firma.value && this.data.form.personalInfo.title.value
                    && this.data.form.personalInfo.name.value && this.data.form.personalInfo.surname.value
                    && this.data.form.personalInfo.phone.value && this.data.form.personalInfo.email.value
                    && this.data.form.privacy.value && this.data.form.upload.value) {
                    return true;
                } else {
                    return false;
                }
            },
            checkForm: function (e) {
                if (this.isError()) {
                    this.hasFormError = false;
                    return true;
                }

                if (!this.data.form.firma.value) {
                    this.data.form.firma.isError = true;
                }

                if (!this.data.form.personalInfo.title.value) {
                    this.data.form.personalInfo.title.isError = true;
                }

                if (!this.data.form.personalInfo.name.value) {
                    this.data.form.personalInfo.name.isError = true;
                }

                if (!this.data.form.personalInfo.surname.value) {
                    this.data.form.personalInfo.surname.isError = true;
                }

                if (!this.data.form.personalInfo.phone.value) {
                    this.data.form.personalInfo.phone.isError = true;
                }

                if (!this.data.form.personalInfo.email.value) {
                    this.data.form.personalInfo.email.isError = true;
                }

                if (!this.data.form.privacy.value) {
                    this.data.form.privacy.isError = true;
                }

                if (!this.data.form.upload.value) {
                    this.data.form.upload.isError = true;
                }

                this.hasFormError = true;
                e.preventDefault();
            },
            onCompanyNameChange: function () {
                if (this.data.form.firma.value) {
                    this.data.form.firma.isError = false;
                } else {
                    this.data.form.firma.isError = true;
                }
            },
            onPersonalInfoTitleChange: function () {
                this.data.form.personalInfo.title.titles.forEach(t => {
                    if (t === this.data.form.personalInfo.title.value) {
                        this.data.form.personalInfo.title.isError = false;
                    }
                });
            },
            onPersonalInfoNameChange: function () {
                if (this.data.form.personalInfo.name.value) {
                    this.data.form.personalInfo.name.isError = false;
                } else {
                    this.data.form.personalInfo.name.isError = true;
                }
            },
            onPersonalInfoSurnameChange: function () {
                if (this.data.form.personalInfo.surname.value) {
                    this.data.form.personalInfo.surname.isError = false;
                } else {
                    this.data.form.personalInfo.surname.isError = true;
                }
            },
            onPersonalInfoPhoneChange: function () {
                if (this.data.form.personalInfo.phone.value) {
                    this.data.form.personalInfo.phone.isError = false;
                } else {
                    this.data.form.personalInfo.phone.isError = true;
                }
            },
            onPrivacyChange: function () {
                if (!this.data.form.privacy.value) {
                    this.data.form.privacy.isError = false;
                } else {
                    this.data.form.privacy.isError = true;
                    this.data.form.upload.value = "set";
                    this.data.form.upload.isError = false;
                }
            },
            onEmailChange: function () {
                if (this.data.form.personalInfo.email.value) {
                        this.data.form.personalInfo.email.isError = false;
                } else {
                    this.data.form.personalInfo.email.isError = true;
                }
            },
            // onUploadChange: function (e) {
            //     const files = e.target.files || e.dataTransfer.files;
            //     if(files[0]) {
            //       this.data.form.upload.value = "set";
            //       this.data.form.upload.isError = false;
            //     } else {
            //       this.data.form.upload.value = "";
            //       this.data.form.upload.isError = true;
            //     }
            // }
        }
    }
</script>

<style scoped>



    .datenschutz .subText,
    .datenschutz .subText a {
        color:#112F61 !important;
    }

    .datenschutz .subText a {
        text-decoration: underline !important
    }

    .files input::-webkit-file-upload-button {
      visibility: hidden;
    }

    #payment-vergleichsangebot .col-12.col-md-10.col-xl-8.p-0.p-md-auto {
        max-width: 50%
    }

    #payment-vergleichsangebot h3 {
        color:#112F61;
        font-size: 24px;
        margin-top: 80px;
        margin-bottom: 20px;
    }

    #payment-vergleichsangebot label,
    #payment-vergleichsangebot .label {
        color:#67696c;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .vue-vergleichsangebot-formular-wrapper {
        padding: 10px 30px 10px 30px;
    }

     #payment-vergleichsangebot input,
     #payment-vergleichsangebot select {
        height: 48px;
        border: 1px solid #003FCE;
        padding: 0 20px;
        color: #003fce
     }


     #payment-vergleichsangebot input:hover,
     #payment-vergleichsangebot select:hover {
        border: 1px solid #112f61;
     }

     #payment-vergleichsangebot input:focus,
     #payment-vergleichsangebot select:focus {
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.1) !important;
        border: 1px solid #112f61;
    }

     #payment-vergleichsangebot select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        position: relative;
     }

     #payment-vergleichsangebot .dropdown {
        position: relative;
     }

     #payment-vergleichsangebot .arrow {
        display: block;
        background-color: #fff;
        position: absolute;
        top: -5px;
        width: 10px;
        height: 10px;
        border-right: 0.1em solid #003FCE;
        border-top: .1em solid #003FCE;
        transform: rotate(135deg);
        margin-right: .5em;
        margin-left: 1em;
        z-index: 99;
        right: 15px;
        top: 17px;
    }

     #payment-vergleichsangebot small {
        color:#67696c;
        font-size: 12px
     }

     #payment-vergleichsangebot input.checkbox {
        height: 24px;
        border: 1px solid #003fce;
        width: 24px;
        float: left;
        padding: 0;
        margin-top: -20px;
        border-radius: 3px;
        -webkit-appearance: none;
     }

     #payment-vergleichsangebot h4,
     #payment-vergleichsangebot .subText>h4 {
        color: #112f61!important
     }

     #payment-vergleichsangebot .checkbox-text {
        width: 85%;
        float: left;
        padding-left: 20px;
     }

     #payment-vergleichsangebot input.submitButtonWrapper {
        text-transform: uppercase;
        color: white !important
     }

    .files input {
        padding: 125px 20px 0 0 !important;
        text-align: center !important;
        margin: 0;
        width: 100% !important;
        outline: 0;
        height: 180px !important
    }

    .files input + label {
        display: none;
    }

    .files {
        position: relative
    }

    .files span {
        position: absolute;
        content: "Ziehen Sie einfach die Abrechnung Ihres derzeitigen Netzbetreibers in dieses Feld.";
        padding: 20px;
        top: 40px;
        left: 100px;
        color: #67696C;
        pointer-events: none;
    }

    .files:before {
        pointer-events: none;
        position: absolute;
        top: 60px;
        left: 50px;
        width: 50px;
        height: 56px;
        content: "";
        display: block;
        margin: 0 auto;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .submitButtonWrapper {
        width: 278px;
        height: 48px;
        border-radius: 2px;
        background-color: #003fce;
        font-family: TheMix-Plain;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    .vue-recall-arrange-input-error {
        font-size: 14px;
        color: #EA0000
    }
</style>
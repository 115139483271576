<template>
  <div class="vue-forder-antrag-stellen-form-wrapper mt-5 mb-5" v-if="data">
    <div class="row container-fluid-max justify-content-center">
      <div class="col-12 text-center">
        <div class="row">
          <div class="col-12 text-center">
            <a style="cursor: pointer;" @click="navigateBack()" class="animate link-primary">
              <img :src="baseUrl + 'assets/images/link-pfeil-links.svg'" v-if="baseUrl"
                   @click="navigateBack()" class="vue-recall-arrange-back-btn" style="margin-right: 5px;">
              {{ data.backButtonLable }}</a>
          </div>
        </div>
      </div>

      <div class="col-12">
        <PageTitleComponent :data="data.header" class="m-0">
        </PageTitleComponent>
      </div>

      <div>
        <form method="post" action="" accept-charset="UTF-8" v-if="data.form" enctype="multipart/form-data">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
              <div v-html="csrfInput"></div>
              <input type="hidden" name="action" value="contact-form/send">
              <div v-html="redirectInput"></div>

              <input type="hidden" name="toEmail" v-bind:value="toEmail">

              <div class="row">
                <div class="col-12 col-lg-8" v-if="data.form.project.plafond">

                  <div class="row">
                    <div class="w-100">
                      <h4 class="text-left" v-if="data.form.project.label">
                        {{ data.form.project.label }}
                      </h4>
                    </div>

                    <template v-if="project === 'plafond' || !project ">

                      <label class="subText text-left mt-5" for="vue-recall-arrange-plafond">
                        {{ data.form.project.plafond.label }}
                      </label>
                      <input id="vue-recall-arrange-plafond" type="text" name="message[Plafond]"
                             class="form-control w-100" @input="onPlafondChange()"
                             v-model="data.form.project.plafond.value">
                      <small class="mt-2">
                        {{ data.form.project.plafond.helpLabel }}
                      </small>
                      <div class="col-12 vue-recall-arrange-input-error subText text-left mt-2"
                           v-if="this.data.form.project.plafond.isError">
                        {{ data.form.project.plafond.errorMsg }}
                      </div>

                    </template>

                    <template v-else-if="project === 'plafond2'">

                      <label class="subText text-left" for="vue-recall-arrange-plafond">
                        {{ data.form.project.plafond2.label }}
                      </label>
                      <input id="vue-recall-arrange-plafond" type="text" name="message[Plafond2]"
                             class="form-control w-100" @input="onPlafondChange2()"
                             v-model="data.form.project.plafond2.value">
                      <small>
                        {{ data.form.project.plafond2.helpLabel }}
                      </small>
                      <div class="col-12 vue-recall-arrange-input-error subText text-left mt-2"
                           v-if="this.data.form.project.plafond2.isError">
                        {{ data.form.project.plafond2.errorMsg }}
                      </div>

                    </template>

                    <template v-else-if="project === 'plafond3'">

                      <label class="subText text-left" for="vue-recall-arrange-plafond">
                        {{ data.form.project.plafond3.label }}
                      </label>
                      <input id="vue-recall-arrange-plafond" type="text" name="message[Plafond3]"
                             class="form-control w-100" @input="onPlafondChange3()"
                             v-model="data.form.project.plafond3.value">
                      <small>
                        {{ data.form.project.plafond3.helpLabel }}
                      </small>
                      <div class="col-12 vue-recall-arrange-input-error subText text-left mt-2"
                           v-if="this.data.form.project.plafond3.isError">
                        {{ data.form.project.plafond3.errorMsg }}
                      </div>

                    </template>

                    <template v-else-if="project === 'plafond4'">

                      <label class="subText text-left" for="vue-recall-arrange-plafond">
                        {{ data.form.project.plafond4.label }}
                      </label>
                      <input id="vue-recall-arrange-plafond" type="text" name="message[D.E.I.N. Plafond]"
                             class="form-control w-100" @input="onPlafondChange4()"
                             v-model="data.form.project.plafond4.value">
                      <small>
                        {{ data.form.project.plafond4.helpLabel }}
                      </small>
                      <div class="col-12 vue-recall-arrange-input-error subText text-left mt-2"
                           v-if="this.data.form.project.plafond4.isError">
                        {{ data.form.project.plafond4.errorMsg }}
                      </div>

                    </template>

                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.company.number">
                  <div class="row">
                    <div class="text-left" v-if="data.form.company.label">
                      <h4 class="mt-5">{{ data.form.company.label }}</h4>
                    </div>
                    <label class="w-100 subText text-left mt-5" for="antrag-company-number">
                      {{ data.form.company.number.label }}
                    </label>
                    <input id="antrag-company-number" type="text" name="message[CompanyNumber]"
                           class="w-100 form-control" @input="onCompanyNumberChange()"
                           v-model="data.form.company.number.value">
                    <div class="vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.company.number.isError">
                      {{ data.form.company.number.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.company.name">
                  <div class="row">
                    <label class="w-100 subText text-left" for="antrag-company-name">
                      {{ data.form.company.name.label }}
                    </label>
                    <input id="antrag-company-name" type="text" name="message[CompanyName]"
                           class="w-100 form-control" @input="onCompanyNameChange()"
                           v-model="data.form.company.name.value">
                    <div class="w-100 vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.company.name.isError">
                      {{ data.form.company.name.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.company.address">
                  <div class="row">
                    <label class="subText text-left" for="antrag-company-address">
                      {{ data.form.company.address.label }}
                    </label>
                    <input id="antrag-company-address" type="text" name="message[CompanyAddress]"
                           class="w-100 form-control" @input="onCompanyAddressChange()"
                           v-model="data.form.company.address.value">
                    <div class="w-100 vue-recall-arrange-input-error subText mt-2 text-left"
                         v-if="this.data.form.company.address.isError">
                      {{ data.form.company.address.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-4" v-if="data.form.company.plz">
                  <div class="row">
                    <label class="text-left subText" for="antrag-company-plz">
                      {{ data.form.company.plz.label }}
                    </label>
                    <input id="antrag-company-plz" type="text" name="message[CompanyPlz]"
                           class="w-100 form-control" @input="onCompanyPlzChange()"
                           v-model="data.form.company.plz.value">
                    <div class="w-100 vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.company.plz.isError">
                      {{ data.form.company.plz.errorMsg }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-8" v-if="data.form.company.location">
                  <div class="row">
                    <label class="subText text-left" for="antrag-company-location">
                      {{ data.form.company.location.label }}
                    </label>
                    <input id="antrag-company-location" type="text" name="message[CompanyLocation]"
                           class="w-100 form-control" @input="onCompanyLocationChange()"
                           v-model="data.form.company.location.value">
                    <div class="vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.company.location.isError">
                      {{ data.form.company.location.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.personalInfo.title">
                  <div class="row">
                    <div class="w-100" v-if="data.form.personalInfo.label">
                      <h4 class="mt-5">{{ data.form.personalInfo.label }}</h4>
                    </div>
                    <label class="subText text-left mt-5" for="antrag-personal-info-title">
                      {{ data.form.personalInfo.title.label }}
                    </label>

                    <div class="dropdown w-100">
                      <div class="arrow"></div>
                      <select v-model="data.form.personalInfo.title.value" class="form-control w-100"
                              id="antrag-personal-info-title" name="message[Title]"
                              @change="onPersonalInfoTitleChange()">
                        <option disabled selected value>{{ data.form.personalInfo.title.helpLabel }}</option>
                        <option :value="tit" v-for="tit in data.form.personalInfo.title.titles" :key="tit">{{ tit }}
                        </option>
                      </select>
                    </div>

                    <div class="vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.personalInfo.title.isError">
                      {{ data.form.personalInfo.title.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.personalInfo.name">
                  <div class="row">
                    <label class="subText text-left" for="antrag-personal-info-name">
                      {{ data.form.personalInfo.name.label }}
                    </label>
                    <input id="antrag-personal-info-name" type="text" name="message[Name]"
                           class="form-control" @input="onPersonalInfoNameChange()"
                           v-model="data.form.personalInfo.name.value">
                    <div class="vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="this.data.form.personalInfo.name.isError">
                      {{ data.form.personalInfo.name.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-8" v-if="data.form.personalInfo.surname">
                  <div class="row">
                    <label class="subText text-left" for="antrag-personal-info-surname">
                      {{ data.form.personalInfo.surname.label }}
                    </label>
                    <input id="antrag-personal-info-surname" type="text" name="message[Surname]"
                           class="form-control w-100" @input="onPersonalInfoSurnameChange()"
                           v-model="data.form.personalInfo.surname.value">
                    <div class="vue-recall-arrange-input-error subText mt-2 text-left"
                         v-if="this.data.form.personalInfo.surname.isError">
                      {{ data.form.personalInfo.surname.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0" v-if="data.form.personalInfo.email">
                <div class="col-12 col-lg-8">
                  <div class="row">
                    <label class="subText text-left" for="vue-recall-arrange-email">
                      {{ data.form.personalInfo.email.label }}
                    </label>
                    <input id="vue-recall-arrange-email" type="email" name="fromEmail"
                           class="w-100 form-control" @input="onEmailChange()"
                           v-model="data.form.personalInfo.email.value">
                    <div class="vue-recall-arrange-input-error subText mt-2 text-left"
                         v-if="this.data.form.personalInfo.email.isError">
                      {{ data.form.personalInfo.email.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-sm-5 mt-0">
                <div class="col-12 col-lg-6" v-if="data.form.personalInfo.phone">
                  <div class="row">
                    <label class="subText text-left" for="antrag-pesonal-info-phone">
                      {{ data.form.personalInfo.phone.label }}
                    </label>
                    <input id="antrag-pesonal-info-phone" type="tel" name="message[Phone]"
                           class="w-100 form-control" @input="onPersonalInfoPhoneChange()"
                           v-model="data.form.personalInfo.phone.value">
                    <div class="vue-recall-arrange-input-error subText mt-2 text-left"
                         v-if="this.data.form.personalInfo.phone.isError">
                      {{ data.form.personalInfo.phone.errorMsg }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6 fax" v-if="data.form.personalInfo.fax">
                  <div class="row">
                    <label class="subText text-left" for="antrag-personal-info-fax">
                      {{ data.form.personalInfo.fax.label }}
                    </label>
                    <input id="antrag-personal-info-fax" type="text" name="message[Fax]"
                           class="form-control w-100">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-9">

              <!-- <div class="row mt-sm-5 mt-0"> -->
                <!-- <div class="col-12 text-left" v-if="data.form.documentation.label">
                  <h4 class="mt-5 pb-5">
                    {{ data.form.documentation.label }}
                  </h4>
                </div> -->

                <!-- <div class="col-12 col-lg-5" v-if="data.form.documentation.upload">
                  <div class="row">
                    <div class="form-group files w-100">
                      <input type="file" class="form-control" multiple name="attachment[]" v-on:change="onUploadChange($event)">
                      <span>{{ data.form.documentation.upload.label }}</span>
                    </div>
                    <div>
                      <small>{{ data.form.documentation.upload.helpLabel }}</small>
                    </div>
                    <div class="vue-recall-arrange-input-error subText mt-2 text-left"
                         v-if="this.data.form.documentation.upload.isError">
                      {{ data.form.documentation.upload.errorMsg }}
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-12 col-lg-7 subText text-left list"
                     v-html="data.form.documentation.upload.text">
                </div> -->
              <!-- </div> -->

              <div class="row mt-sm-5 mt-0">
                <div class="col-10" v-if="data.form.privacy">
                  <div class="row align-items-center">
                    <input id="vue-recall-arrange-privacy" type="checkbox" name="vue-recall-arrange-privacy"
                           class="checkbox form-control" @input="onPrivacyChange()"
                           v-model="data.form.privacy.value">
                    <div class="checkbox-text subText text-left" for="vue-recall-arrange-privacy"
                         v-html="data.form.privacy.label"></div>
                    <div class="w-100 vue-recall-arrange-input-error mt-2 subText text-left"
                         v-if="data.form.privacy.isError">
                      {{ data.form.privacy.errorMsg }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-10" v-if="data.form.submitButton">
                  <div class="row">
                    <input type="submit" :value="data.form.submitButton.label"
                           class="form-control primary-btn" @click="checkForm">
                  </div>
                </div>
              </div>

              <div class="row mt-3" v-if="(!hasFormSomeError) && hasFormError">
                <div class="col-1">
                  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 21.8 29" style="enable-background:new 0 0 21.8 29;" xml:space="preserve">
                                <g id="Gruppe_3395" transform="translate(-1111.405 -583)">
                                    <g id="Gruppe_1754" transform="translate(266.775 -2443.232)">
                                        <g id="Pfad_3416">
                                            <path class="st0" style="fill:#EA0000;" d="M855.6,3051.1c-6,0-10.9-4.9-10.9-10.9c0-6,4.9-10.9,10.9-10.9s10.9,4.9,10.9,10.9c0,4.7-3,8.9-7.5,10.4
                                                C857.9,3050.9,856.7,3051.1,855.6,3051.1z"/>
                                        </g>
                                    </g>
                                  <g class="st1">
                                        <path class="st2" style="fill:#FFFFFF;" d="M1123.9,602.8c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                                            C1123.3,601.5,1123.9,602.1,1123.9,602.8z M1123.5,600.6h-2l-0.4-9.5h2.7L1123.5,600.6z"/>
                                    </g>
                                </g>
                                </svg>
                </div>
                <div class="col-11 vue-recall-arrange-input-error subText mt-2 text-left">
                  {{ data.formErrorMsg }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "../PageTitleComponent";
import {UtilityService} from "../../../services/utility-service";

export default {
  name: "ForderantragStellenFormComponent",
  components: {PageTitleComponent},
  props: ['data', 'csrfInput', 'redirectInput', 'baseUrl', 'toEmail'],
  data: function () {
    return {
      hasFormError: false,
      utility: new UtilityService(),
      project: null
    }
  },
  computed: {
    hasFormSomeError: function () {
      return this.isError()
    }
  },
  methods: {
    getPfeil: () => this.baseUrl + 'assets/images/link-pfeil-links.svg',

    navigateBack: () => window.history.back(),

    isError: function () {
      var plafondproject;

      if(this.project === 'plafond' || !this.project){
        plafondproject = this.data.form.project.plafond.value;
      }
      else if(this.project === 'plafond2' || !this.project){
        plafondproject = this.data.form.project.plafond2.value;
      }
      else if(this.project === 'plafond3' || !this.project){
        plafondproject = this.data.form.project.plafond3.value;
      }
      else if(this.project === 'plafond4' || !this.project){
        plafondproject = this.data.form.project.plafond4.value;
      }            

      if (plafondproject && this.data.form.company.number.value
          && this.data.form.company.name.value && this.data.form.company.address.value
          && this.data.form.company.plz.value && this.data.form.company.location.value
          && this.data.form.personalInfo.title.value && this.data.form.personalInfo.name.value
          && this.data.form.personalInfo.surname.value && this.data.form.personalInfo.phone.value
          && this.data.form.privacy.value && this.data.form.personalInfo.email.value && this.data.form.documentation.upload.value) {

        return true;
      } else {
        return false;
      }
    },

    checkForm: function (e) {
      if (this.isError()) {
        this.hasFormError = false;
        return true;
      }

      if (!this.data.form.project.plafond.value) {
        this.data.form.project.plafond.isError = true;
      }

      if (!this.data.form.project.plafond2.value) {
        this.data.form.project.plafond2.isError = true;
      }

      if (!this.data.form.project.plafond3.value) {
        this.data.form.project.plafond3.isError = true;
      }

      if (!this.data.form.project.plafond4.value) {
        this.data.form.project.plafond4.isError = true;
      }

      if (!this.data.form.company.number.value) {
        this.data.form.company.number.isError = true;
      }

      if (!this.data.form.company.name.value) {
        this.data.form.company.name.isError = true;
      }

      if (!this.data.form.company.address.value) {
        this.data.form.company.address.isError = true;
      }

      if (!this.data.form.company.plz.value) {
        this.data.form.company.plz.isError = true;
      }

      if (!this.data.form.company.location.value) {
        this.data.form.company.location.isError = true;
      }

      if (!this.data.form.personalInfo.title.value) {
        this.data.form.personalInfo.title.isError = true;
      }

      if (!this.data.form.personalInfo.name.value) {
        this.data.form.personalInfo.name.isError = true;
      }

      if (!this.data.form.personalInfo.surname.value) {
        this.data.form.personalInfo.surname.isError = true;
      }

      if (!this.data.form.personalInfo.phone.value) {
        this.data.form.personalInfo.phone.isError = true;
      }

      if (!this.data.form.privacy.value) {
        this.data.form.privacy.isError = true;
      }

      if (!this.data.form.personalInfo.email.value) {
        this.data.form.personalInfo.email.isError = true;
      }

      if (!this.data.form.documentation.upload.value) {
        this.data.form.documentation.upload.isError = true;
      }

      this.hasFormError = true;
      e.preventDefault();
    },
    onPlafondChange4: function () {
      if (this.data.form.project.plafond4.value) {
        this.data.form.project.plafond4.isError = false;
      } else {
        this.data.form.project.plafond4.isError = true;
      }
    },
    onPlafondChange3: function () {
      if (this.data.form.project.plafond3.value) {
        this.data.form.project.plafond3.isError = false;
      } else {
        this.data.form.project.plafond3.isError = true;
      }
    },
    onPlafondChange2: function () {
      if (this.data.form.project.plafond2.value) {
        this.data.form.project.plafond2.isError = false;
      } else {
        this.data.form.project.plafond2.isError = true;
      }
    },
    onPlafondChange: function () {
      if (this.data.form.project.plafond.value) {
        this.data.form.project.plafond.isError = false;
      } else {
        this.data.form.project.plafond.isError = true;
      }
    },
    onCompanyNumberChange: function () {
      if (this.data.form.company.number.value) {
        this.data.form.company.number.isError = false;
      } else {
        this.data.form.company.number.isError = true;
      }
    },
    onCompanyNameChange: function () {
      if (this.data.form.company.name.value) {
        this.data.form.company.name.isError = false;
      } else {
        this.data.form.company.name.isError = true;
      }
    },
    onCompanyAddressChange: function () {
      if (this.data.form.company.address.value) {
        this.data.form.company.address.isError = false;
      } else {
        this.data.form.company.address.isError = true;
      }
    },
    onCompanyPlzChange: function () {
      if (this.data.form.company.plz.value) {
        this.data.form.company.plz.isError = false;
      } else {
        this.data.form.company.plz.isError = true;
      }
    },
    onCompanyLocationChange: function () {
      if (this.data.form.company.location.value) {
        this.data.form.company.location.isError = false;
      } else {
        this.data.form.company.location.isError = true;
      }
    },
    onPersonalInfoTitleChange: function () {
      this.data.form.personalInfo.title.titles.forEach(t => {
        if (t === this.data.form.personalInfo.title.value) {
          this.data.form.personalInfo.title.isError = false;
        }
      });
    },
    onPersonalInfoNameChange: function () {
      if (this.data.form.personalInfo.name.value) {
        this.data.form.personalInfo.name.isError = false;
      } else {
        this.data.form.personalInfo.name.isError = true;
      }
    },
    onPersonalInfoSurnameChange: function () {
      if (this.data.form.personalInfo.surname.value) {
        this.data.form.personalInfo.surname.isError = false;
      } else {
        this.data.form.personalInfo.surname.isError = true;
      }
    },
    onPersonalInfoPhoneChange: function () {
      if (this.data.form.personalInfo.phone.value) {
        this.data.form.personalInfo.phone.isError = false;
      } else {
        this.data.form.personalInfo.phone.isError = true;
      }
    },
    onPrivacyChange: function () {
      if (!this.data.form.privacy.value) {
        this.data.form.privacy.isError = false;
      } else {
        this.data.form.privacy.isError = true;
      }
    },
    onEmailChange: function () {
      if (this.data.form.personalInfo.email.value) {
        this.data.form.personalInfo.email.isError = false;
        this.data.form.documentation.upload.value = "set";
        this.data.form.documentation.upload.isError = false;
      } else {
        this.data.form.personalInfo.email.isError = true;
      }
    },
    // onUploadChange: function (e) {
    //   const files = e.target.files || e.dataTransfer.files;
    //   if(files[0]) {
    //     this.data.form.documentation.upload.value = "set";
    //     this.data.form.documentation.upload.isError = false;
    //   } else {
    //     this.data.form.documentation.upload.value = "";
    //     this.data.form.documentation.upload.isError = true;
    //   }
    // }
  },
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const project = urlParams.get('project');
    this.project = project;
  }
}
</script>

<style scoped>

.primary-btn {
  width: 278px;
  height: 48px;
  border-radius: 2px;
  background-color: #003fce;
  font-family: TheMix-Plain;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.list ul li:fist-child {
  margin-top: 0 !important
}

.vue-forder-antrag-stellen-form-wrapper {
  margin-top: 200px !important
}

.vue-recall-arrange-back-btn:hover {
  cursor: pointer;
}

.datenschutz .subText,
.datenschutz .subText a {
  color: #112F61 !important;
}

.datenschutz .subText a {
  text-decoration: underline !important
}

.files input::-webkit-file-upload-button {
  visibility: hidden;
}

.col-12.col-md-10.col-xl-8.p-0.p-md-auto {
  max-width: 50%
}

h3 {
  color: #112F61;
  font-size: 24px;
  margin-top: 80px;
  margin-bottom: 20px;
}

label,
.label {
  color: #67696c;
  margin-bottom: 20px;
  margin-top: 15px;
}

.vue-vergleichsangebot-formular-wrapper {
  padding: 10px 30px 10px 30px;
}

input,
select {
  height: 48px;
  border: 1px solid #003FCE;
  padding: 0 20px;
  color: #003fce
}


input:hover,
select:hover {
  border: 1px solid #112f61;
}

input:focus,
select:focus {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1) !important;
  border: 1px solid #112f61;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
}

.dropdown {
  position: relative;
}

.arrow {
  display: block;
  background-color: #fff;
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-right: 0.1em solid #003FCE;
  border-top: .1em solid #003FCE;
  transform: rotate(135deg);
  margin-right: .5em;
  margin-left: 1em;
  z-index: 99;
  right: 15px;
  top: 17px;
}

small {
  color: #67696c;
  font-size: 12px
}

input.checkbox {
  height: 24px;
  border: 1px solid #003fce;
  width: 24px;
  float: left;
  padding: 0;
  margin-top: -20px;
  border-radius: 3px;
  -webkit-appearance: none;
}

h4,
.subText > h4 {
  color: #112f61 !important
}

.checkbox-text {
  width: 85%;
  float: left;
  padding-left: 20px;
}

input.submitButtonWrapper {
  text-transform: uppercase;
  color: white !important
}

.files input {
  padding: 125px 20px 0 0 !important;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  outline: 0;
  height: 180px !important
}

.files input + label {
  display: none;
}

.files {
  position: relative
}

.files span {
  position: absolute;
  content: "Ziehen Sie einfach die Abrechnung Ihres derzeitigen Netzbetreibers in dieses Feld.";
  padding: 20px;
  top: 50px;
  left: 100px;
  color: #67696C;
  pointer-events: none;
}

.files:before {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 50px;
  width: 50px;
  height: 56px;
  content: "";
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.submitButtonWrapper {
  width: 278px;
  height: 48px;
  border-radius: 2px;
  background-color: #003fce;
  font-family: TheMix-Plain;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.vue-recall-arrange-input-error {
  font-size: 14px;
  color: #EA0000
}
</style>
